import { Td, Text } from '@chakra-ui/react';
import { MembershipStatusBadge } from '../../../components';
import { useFetchPatronMembershipByClub } from '../../../hooks';
import { IClub, IPatron } from '../../../types';
import { useMemo } from 'react';
import dayjs from 'dayjs';

interface IProps {
  club: IClub;
  patron: IPatron;
}

export default function PatronsTableMembershipStatus({
  club,
  patron,
}: IProps): JSX.Element {
  const [membership, isLoading] = useFetchPatronMembershipByClub(
    patron.id,
    club.id,
  );

  const expirationDate = useMemo(() => {
    if (membership?.expiresAt === null || membership?.isActive === false) {
      return '-';
    }

    if (!membership?.expiresAt) {
      return undefined;
    }

    return dayjs(membership.expiresAt.toDate()).format('MM/DD/YYYY');
  }, [membership]);

  if (isLoading) {
    return (
      <>
        <Td>
          <Text
            bg="gray.50"
            display="inline"
            fontSize="xs"
            fontWeight="medium"
            px={1}
            py={0.5}
            rounded="md"
          >
            Loading...
          </Text>
        </Td>
        <Td>
          <Text
            bg="gray.50"
            display="inline"
            fontSize="xs"
            fontWeight="medium"
            px={1}
            py={0.5}
            rounded="md"
          >
            Loading...
          </Text>
        </Td>
      </>
    );
  }

  return (
    <>
      <Td>
        {membership ? <MembershipStatusBadge membership={membership} /> : '-'}
      </Td>
      <Td>{expirationDate}</Td>
    </>
  );
}
