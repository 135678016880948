import { useEffect, useState } from 'react';
import { useGetBrewery } from '../contexts';
import { ProductPlan } from '../types';

type Features = {
  hasClubRewards: boolean;
};

export default function useGetFeatures(): Features {
  const [brewery] = useGetBrewery();
  const [features, setFeatures] = useState<Features>({
    hasClubRewards: false,
  });

  useEffect(() => {
    if (!brewery) {
      return;
    }

    const { plan } = brewery;

    const hasClubRewards = getHasClubRewards(plan);

    setFeatures({
      hasClubRewards,
    });
  }, [brewery]);

  return features;
}

function getHasClubRewards(plan: ProductPlan): boolean {
  return plan === ProductPlan.CORE || plan === ProductPlan.COMPLETE;
}
