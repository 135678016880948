import { Box, Divider } from '@chakra-ui/react';
import { Timestamp } from 'firebase/firestore';
import Basics from './Basics';
import Benefits from './Benefits';
import Cadence from './Cadence';
import CustomQuestions from './CustomQuestions';
import EditInfo from './EditInfo';
import Rewards from './Rewards';
import Terms from './Terms';
import { useGetFeatures } from '../../hooks';
import { ClubStatus, IClub, IClubReward } from '../../types';

interface Props {
  club: IClub;
  onChange: (
    key: keyof IClub,
    value: string | string[] | null | number | IClubReward[] | Timestamp,
  ) => void;
}

export default function ClubDetails({
  club,
  onChange,
}: Props): JSX.Element | null {
  const { hasClubRewards } = useGetFeatures();
  const { status } = club;
  const isClubDraft = status === ClubStatus.DRAFT;
  const isClubComplete = status === ClubStatus.COMPLETE;

  return (
    <Box>
      <Box mb={6}>
        <EditInfo club={club} />
      </Box>

      <Basics club={club} isEditable={!isClubComplete} onChange={onChange} />

      <Divider my={6} />

      <Cadence club={club} isEditable={isClubDraft} onChange={onChange} />

      <Divider my={6} />

      <Benefits club={club} isEditable={!isClubComplete} onChange={onChange} />

      <Divider my={6} />

      {hasClubRewards && (
        <>
          <Rewards club={club} isEditable={isClubDraft} onChange={onChange} />
          <Divider my={6} />
        </>
      )}

      <CustomQuestions
        club={club}
        isEditable={!isClubComplete}
        onChange={onChange}
      />

      <Divider my={6} />

      <Terms club={club} isEditable={!isClubComplete} onChange={onChange} />
    </Box>
  );
}
