import {
  Button,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
}

export default function ImportPatronsNotification({
  isOpen,
  onClose,
}: IProps): JSX.Element {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Import Patrons</ModalHeader>
        <ModalCloseButton />
        <ModalBody display="grid" gap={2}>
          <Text>👋 Hello,</Text>

          <Text>We are happy to help import your existing club members!</Text>

          <Text>
            To get started, please make a copy of (or download) our{' '}
            <Link
              fontWeight="bold"
              href="https://docs.google.com/spreadsheets/d/1flnAFfi1SCGL9fioXxu_IIVxTTewWynpg86V15IdoXQ/edit?usp=sharing"
              target="_blank"
            >
              member import template spreadsheet.
            </Link>
          </Text>

          <Text>
            Once you've filled out your copy of the spreadsheet, please email it
            to us at <b>help@mugclub.beer</b>.
          </Text>

          <Text>Further instructions can be found in the spreadsheet.</Text>

          <Text>Cheers,</Text>

          <Text>The MugClub.beer team</Text>
        </ModalBody>

        <ModalFooter>
          <Button onClick={onClose}>Close</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
