import { useMemo } from 'react';
import { Box, Text } from '@chakra-ui/react';
import { PatronsTable } from '../../../components';
import { useFetchPatronMembershipsByExpirationDate } from '../../../hooks';
import { IClub, IMembership, IPatron } from '../../../types';

interface IProps {
  club: IClub;
  patrons: IPatron[];
}

export default function UpcomingPatronExpirations({
  club,
  patrons,
}: IProps): JSX.Element {
  const [memberships, loading] = useFetchPatronMembershipsByExpirationDate(
    club?.id,
    30,
  );
  const patronsWithUpcomingExpirations = useMemo(
    () => getPatronsWithUpcomingExpirations(memberships, patrons),
    [memberships, patrons],
  );

  return (
    <>
      <Box mb={4}>
        <Text fontWeight="medium">Upcoming Membership Expirations</Text>
        <Text color="gray.500" fontSize="sm">
          Memberships which expire in the next 30 days.
        </Text>
      </Box>

      <PatronsTable
        club={club}
        emptyStateTitle="No Upcoming Membership Expirations"
        emptyStateDescription="Members will appear here 30 days before their membership expiration date."
        loading={loading}
        patrons={patronsWithUpcomingExpirations}
      />
    </>
  );
}

function getPatronsWithUpcomingExpirations(
  memberships?: IMembership[],
  patrons?: IPatron[],
): IPatron[] {
  if (!memberships || !patrons) {
    return [];
  }

  const returnValue: IPatron[] = [];

  memberships.forEach((membership) => {
    const patron = patrons.find((patron) => patron.id === membership.patronId);

    if (patron) {
      returnValue.push(patron);
    }
  });

  return returnValue;
}
