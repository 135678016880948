import { useMemo } from 'react';
import Metric from './Metric';
import { useFetchPatronMembershipsByClub } from '../../../../hooks';
import { patronMembershipStatus } from '../../../../utils';
import { IClub, IMembership, IPatron } from '../../../../types';

interface IProps {
  club: IClub;
  patrons: IPatron[];
}

export default function PatronStatusMetrics({
  club,
  patrons,
}: IProps): JSX.Element {
  const [memberships, loading] = useFetchPatronMembershipsByClub(club.id);
  const { active, overdue, inactive } = useMemo(
    () => getMembershipCountsByStatus(memberships),
    [memberships],
  );

  return (
    <>
      <Metric
        label="Active Memberships"
        loading={loading}
        number={active}
        description="# of active memberships"
      />
      <Metric
        label="Overdue Memberships"
        loading={loading}
        number={overdue}
        description="# of overdue memberships"
      />
      <Metric
        label="Inactive Memberships"
        loading={loading}
        number={inactive}
        description="# of inactive membershpis"
      />
    </>
  );
}

function getMembershipCountsByStatus(
  memberships: IMembership[],
): Record<string, number> {
  const active = memberships.filter(
    (membership) => patronMembershipStatus(membership) === 'active',
  ).length;
  const overdue = memberships.filter(
    (membership) => patronMembershipStatus(membership) === 'overdue',
  ).length;
  const inactive = memberships.filter(
    (membership) => patronMembershipStatus(membership) === 'inactive',
  ).length;

  return {
    active,
    overdue,
    inactive,
  };
}
