import { useEffect, useState } from 'react';
import {
  collection,
  deleteDoc,
  doc,
  onSnapshot,
  orderBy,
  query,
  where,
} from 'firebase/firestore';
import {
  Box,
  Button,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useToast,
} from '@chakra-ui/react';
import { db } from '../../../firebase';
import { IClub, IRecord } from '../../../types';
import { EmptyState } from '../../../components';

interface IProps {
  club: IClub;
  patronId: string;
}

export default function PatronVisits({ club, patronId }: IProps): JSX.Element {
  const [records, setRecords] = useState<IRecord[]>([]);
  const toast = useToast();

  async function handleOnRemoveRecord(recordId: string): Promise<void> {
    const recordsRef = doc(db, `records/${recordId}`);

    try {
      await deleteDoc(recordsRef);

      toast({
        description: 'Record removed',
        status: 'success',
        title: 'Success!',
      });
    } catch (error) {
      toast({
        description:
          'An error occurred removing this record. Please try again.',
        status: 'error',
        title: 'Uh Oh.',
      });
    }
  }

  useEffect(() => {
    let unsubscribe: any;
    const recordsRef = collection(db, 'records');
    const patronRef = doc(db, 'patrons', patronId);
    const clubRef = doc(db, 'clubs', club.id);
    const q = query(
      recordsRef,
      where('patronRef', '==', patronRef),
      where('clubRef', '==', clubRef),
      orderBy('createdAt', 'desc'),
    );

    unsubscribe = onSnapshot(q, (recordsCollection) => {
      const records: IRecord[] = [];
      recordsCollection.forEach((doc) => {
        records.push({
          id: doc.id,
          ...doc.data(),
        } as IRecord);
      });

      setRecords(records);
    });

    return () => unsubscribe();
  }, [club, patronId]);

  return (
    <Box>
      {Boolean(records.length) && (
        <Text
          color="gray.500"
          fontSize="sm"
          fontWeight="medium"
          textAlign="right"
          mb={3}
        >
          {records.length} {records.length === 1 ? 'visit' : 'visits'}
        </Text>
      )}

      {records.length ? (
        <TableContainer>
          <Table variant="simple" size="sm">
            <Thead>
              <Tr>
                <Th>Visit No.</Th>
                <Th>Date</Th>
                <Th>Club</Th>
                <Th>Recorded By</Th>
                <Th />
              </Tr>
            </Thead>
            <Tbody>
              {records.map((record, idx) => {
                const visitNumber = records.length - idx;

                return (
                  <Tr key={record.id}>
                    <Td>{visitNumber}</Td>
                    <Td>{record.createdAt.toDate().toLocaleDateString()}</Td>
                    <Td>{club.name}</Td>
                    <Td>{record.bartender.name}</Td>
                    <Td display="flex" justifyContent="flex-end">
                      <Button
                        colorScheme="gray"
                        size="xs"
                        onClick={() => handleOnRemoveRecord(record.id)}
                        variant="outline"
                      >
                        Remove
                      </Button>
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </TableContainer>
      ) : (
        <EmptyState
          heading="No Visits Found"
          description="Member visits will be displayed here."
        />
      )}
    </Box>
  );
}
